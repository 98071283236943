import { ForwardToInboxOutlined } from "@mui/icons-material";
import { SvgIconTypeMap, ChipProps } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  AccountClock,
  LocationExit,
  Bed,
  CheckCircleOutline,
  TimerSandEmpty,
} from "mdi-material-ui";

import { Sailor } from "types";

export const STATE_LABELS: Record<string, string> = {
  invited: "Invité",
  onboarding: "Embarquement",
  onboarded: "Embarqué",
  offboarding: "Débarquement",
  offboarded: "Débarqué",
};

export const STATE_CHIP_COLORS: Record<
  string,
  {
    color: ChipProps["color"];
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  }
> = {
  invited: {
    color: "primary",
    icon: ForwardToInboxOutlined,
  },
  onboarding: {
    color: "info",
    icon: TimerSandEmpty,
  },
  onboarded: {
    color: "success",
    icon: CheckCircleOutline,
  },
  offboarding: {
    color: "warning",
    icon: LocationExit,
  },
  offboarded: {
    color: "error",
    icon: Bed,
  },
};

export const MISSION_STATUS_CHIP: Record<
  Sailor["missionStatus"],
  {
    label: string;
    color: ChipProps["color"];
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  }
> = {
  incoming: {
    label: "À venir",
    color: "warning",
    icon: AccountClock,
  },
  ongoing: {
    label: "En mission",
    color: "info",
    icon: TimerSandEmpty,
  },
  ended: {
    label: "Terminée",
    color: "success",
    icon: CheckCircleOutline,
  },
};

export const OFFBOARDING_REASONS: Record<string, string> = {
  fixed_trial_period_ending_embarq: "Rupture PE initiative Embarq - CDD",
  fixed_trial_period_ending_sailor: "Rupture PE initiative consultant - CDD",
  fixed_ending_mutual_consent: "Rupture d'un commun accord - CDD",
  fixed_contract_ending: "Fin de contrat - CDD",
  permanent_trial_period_ending_embarq: "Rupture PE initiative Embarq - CDI",
  permanent_trial_period_ending_sailor:
    "Rupture PE initiative consultant - CDI",
  permanent_sailor_resignation: "Démission - CDI",
  permanent_ending_mutual_consent: "Rupture conventionnelle - CDI",
  dismissal: "Licenciement",
  retirement: "Départ à la retraite à l'initiative du salarié",
  deceased: "Décès",
};

export const REQUIRED_SAILOR_INFOS = [
  "gender",
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "occupationalCategory",
  "occupationalSubcategory",
  "occupation",
  "birthDate",
  "birthCity",
  "birthCountry",
  "nationality",
  "socialSecurityNumber",
  "address",
  "postalCode",
  "city",
];

export const REQUIRED_SAILOR_BANK_INFO = ["bic", "iban"];

export const REQUIRED_SAILOR_DOCS = [
  "id_card",
  "social_security",
  "address_proof",
  "resume",
];

export type OccupationalCategoryTypes =
  | "management"
  | "information"
  | "consulting"
  | "marketing"
  | "other";

export const OCCUPATIONAL_CATEGORY_LABELS: Record<
  OccupationalCategoryTypes,
  string
> = {
  management: "Management de transition",
  information: "IT & développement",
  consulting: "Conseil",
  marketing: "Marketing & communication",
  other: "Autre",
};

export type ManagementSubcategoryTypes =
  | "finance"
  | "human_resources"
  | "general_direction"
  | "supplies"
  | "purchases"
  | "logistics"
  | "legal_management"
  | "commercial"
  | "information_systems"
  | "other_management";

export const MANAGEMENT_SUBCATEGORY_LABELS: Record<
  ManagementSubcategoryTypes,
  string
> = {
  finance: "Finance",
  human_resources: "Ressources humaines",
  general_direction: "Direction générale",
  supplies: "Supply chain",
  purchases: "Achats",
  logistics: "Direction logistique",
  legal_management: "Direction jurique",
  commercial: "Direction commerciale",
  information_systems: "DSI",
  other_management: "Autre",
};

export type InformationSubcategoryTypes =
  | "product_owner"
  | "web_development"
  | "infrastructure"
  | "project_manager"
  | "data_science"
  | "research"
  | "security"
  | "other_information";

export const INFORMATION_SUBCATEGORY_LABELS: Record<
  InformationSubcategoryTypes,
  string
> = {
  product_owner: "PO/PM",
  web_development: "Développement web",
  infrastructure: "Infrastructure & réseaux",
  project_manager: "Gestion de Projet IT",
  data_science: "Data science",
  research: "R&D, BI",
  security: "Sécurite, cybersécurité",
  other_information: "Autre",
};

export type ConsultingSubcategoryTypes =
  | "market_analysis"
  | "sales_development"
  | "acquisition"
  | "strategic_planning"
  | "change_management"
  | "audit"
  | "optimisation"
  | "legal_consulting"
  | "digital_consulting"
  | "communications"
  | "health"
  | "other_consulting";

export const CONSULTING_SUBCATEGORY_LABELS: Record<
  ConsultingSubcategoryTypes,
  string
> = {
  market_analysis: "Analyse de marché",
  sales_development: "Développement commercial",
  acquisition: "Fusion & acquisition",
  strategic_planning: "Planification stratégique",
  change_management: "Gestion du changement",
  audit: "Audit & contrôle",
  optimisation: "Optimisation des processus",
  legal_consulting: "Juridique",
  digital_consulting: "Digital",
  communications: "Communication",
  health: "Santé et recherche",
  other_consulting: "Autre",
};

export type MarketingSubcategoryTypes =
  | "digital_marketing"
  | "brand_management"
  | "product"
  | "other_marketing";

export const MARKETING_SUBCATEGORY_LABELS: Record<
  MarketingSubcategoryTypes,
  string
> = {
  digital_marketing: "Marketing digital",
  brand_management: "Gestion de marque",
  product: "Product marketing",
  other_marketing: "Autre",
};

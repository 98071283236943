import gql from "graphql-tag";

export default gql`
  fragment sailor on Sailor {
    id
    firstName
    lastName
    email
    unconfirmedEmail
    gender
    profilePic
    state
    welcomed
    confirmationLink
    phoneNumber
    occupationalCategory
    occupationalSubcategory
    occupation
    address
    addressSupplement
    postalCode
    city
    lunchVouchers
    birthDate
    birthCountry
    birthCity
    socialSecurityNumber
    nationality
    residencePermitId
    residencePermitType
    residencePermitExpirationDate
    payInstruction
    referralCode
    iban
    bic
    activityTourCompleted
    silaeId
  }
`;

import {
  CalculateOutlined,
  DirectionsBoat,
  Payments,
  RequestQuote,
  MedicalServicesOutlined,
  GroupsOutlined,
} from "@mui/icons-material";
import {
  BriefcaseOutline,
  BriefcaseDownload,
  Calendar,
  ChartArc,
  CurrencyEur,
  FileDocumentOutline,
  AccountTie,
  FolderMultipleOutline,
  RoomServiceOutline,
  AccountGroup,
  Domain,
  HelpCircle,
  ViewDashboard,
  RocketLaunchOutline,
  TrendingUp,
  SilverwareForkKnife,
  CreditCardRefund,
  BriefcaseClock,
  GiftOutline,
  MovieOutline,
} from "mdi-material-ui";

import theme from "utils/theme";

const TX_BASE_COLORS = {
  color: theme.palette.info.dark,
  bgColor: theme.palette.info.background,
};

const CATEGORY_ICON_TYPES = {
  mission: {
    icon: BriefcaseOutline,
    color: theme.palette.primary.dark,
    bgColor: theme.palette.primary.background,
  },
  activity: {
    icon: Calendar,
    color: theme.palette.secondary.dark,
    bgColor: theme.palette.secondary.background,
  },
  invoice: {
    icon: AccountTie,
    color: theme.palette.success.dark,
    bgColor: theme.palette.success.background,
  },
  expense: {
    icon: FileDocumentOutline,
    color: theme.palette.info.dark,
    bgColor: theme.palette.info.background,
  },
  payment: {
    icon: Payments,
    color: theme.palette.secondary.dark,
    bgColor: theme.palette.secondary.background,
  },
  contract: {
    icon: BriefcaseClock,
    color: theme.palette.warning.dark,
    bgColor: theme.palette.warning.background,
  },
  document: {
    icon: FolderMultipleOutline,
    color: theme.palette.info.main,
    bgColor: theme.palette.info.background,
  },
  exports: {
    icon: BriefcaseDownload,
    color: theme.palette.info.main,
    bgColor: theme.palette.info.background,
  },
  service: {
    icon: RoomServiceOutline,
    color: theme.palette.specials.yellow,
    bgColor: theme.palette.specials.yellowBackground,
  },
  sailor: {
    icon: AccountGroup,
    color: theme.palette.secondary.main,
    bgColor: theme.palette.secondary.background,
  },
  client: {
    icon: Domain,
    color: theme.palette.secondary.main,
    bgColor: theme.palette.secondary.background,
  },
  simulator: {
    icon: CalculateOutlined,
    color: theme.palette.secondary.main,
    bgColor: theme.palette.secondary.background,
  },
  stats: {
    icon: ChartArc,
    color: theme.palette.secondary.main,
    bgColor: theme.palette.secondary.background,
  },
  crewMember: {
    icon: DirectionsBoat,
    color: theme.palette.secondary.main,
    bgColor: theme.palette.secondary.background,
  },
  help: {
    icon: HelpCircle,
    color: theme.palette.common.white,
    bgColor: theme.palette.secondary.main,
  },
  dashboard: {
    icon: ViewDashboard,
    color: theme.palette.secondary.main,
    bgColor: theme.palette.secondary.background,
  },
  rocket: {
    icon: RocketLaunchOutline,
    color: theme.palette.info.main,
    bgColor: theme.palette.info.background,
  },
  users: {
    icon: AccountGroup,
    color: "#129DEC",
    bgColor: "rgba(18, 157, 236, 0.10)",
  },
  quotes: {
    icon: RequestQuote,
    color: theme.palette.info.main,
    bgColor: theme.palette.info.background,
  },
  referral: {
    icon: GiftOutline,
    color: theme.palette.success.main,
    bgColor: theme.palette.success.background,
  },
  tx_invoice: {
    icon: BriefcaseOutline,
    color: theme.palette.success.main,
    bgColor: theme.palette.success.background,
  },
  tx_activity_report: {
    icon: CurrencyEur,
    ...TX_BASE_COLORS,
  },
  tx_lunch_voucher: {
    icon: SilverwareForkKnife,
    ...TX_BASE_COLORS,
  },
  tx_employee_savings: {
    icon: TrendingUp,
    ...TX_BASE_COLORS,
  },
  tx_refund: {
    icon: CreditCardRefund,
    ...TX_BASE_COLORS,
  },
  tx_other: {
    icon: CurrencyEur,
    ...TX_BASE_COLORS,
  },
  tx_christmas_voucher: {
    icon: GiftOutline,
    ...TX_BASE_COLORS,
  },
  tx_culture_voucher: {
    icon: MovieOutline,
    ...TX_BASE_COLORS,
  },
  tx_medical: {
    icon: MedicalServicesOutlined,
    ...TX_BASE_COLORS,
  },
  tx_employee_committee: {
    icon: GroupsOutlined,
    ...TX_BASE_COLORS,
  },
};

export type CategoryIconTypes = keyof typeof CATEGORY_ICON_TYPES;

export default CATEGORY_ICON_TYPES;

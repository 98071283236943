import { isToday } from "date-fns";

import { CategoryIconTypes } from "constants/categoryIconTypes";
import { Payment, Settlement, PendingInvoice, Transaction } from "types";
import { formatDate } from "utils/dates";

export interface TransactionItemProps {
  transaction: Transaction;
  future?: boolean;
}

const transactionMonth = (transaction: Transaction) =>
  transaction.__typename === "Settlement"
    ? transaction.invoice.month
    : transaction.month;

const transactionDate = (transaction: Transaction) => {
  if (transaction.__typename === "Settlement") return transaction.date;
  if (transaction.__typename === "Payment" && transaction.registeredAt)
    return transaction.registeredAt;
  return transaction.month;
};

export const formatTransactionDate = (t: Transaction) => {
  const date = transactionDate(t);
  return isToday(new Date(date))
    ? "Aujourd'hui"
    : formatDate(t.__typename === "Invoice" && t.dueDate ? t.dueDate : date, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
};

const labelForInvoiceOrSettlement = (
  transaction: Settlement | PendingInvoice
): string => {
  if (transaction.__typename === "Settlement")
    return `Règlement de ${transaction.invoice.client.name} pour ${formatDate(
      transactionMonth(transaction),
      { month: "long" }
    )}`;
  return `Règlement attendu de ${transaction.client.name} pour ${formatDate(
    transactionMonth(transaction),
    { month: "long" }
  )}`;
};

const labelForPayment = (payment: Payment): string => {
  if (
    payment.paymentType === "other" &&
    payment.description?.toLowerCase().includes("leeto")
  ) {
    return `Versement Leeto en ${formatDate(payment.month, {
      month: "long",
    })}`;
  }
  switch (payment.paymentType) {
    case "activity_report":
      return `Salaire versé pour ${formatDate(payment.month, {
        month: "long",
      })}`;
    case "lunch_voucher":
      return `Versement titres restaurant pour ${formatDate(payment.month, {
        month: "long",
      })}`;
    case "employee_savings":
      return `Versement PEE/PERCO pour ${formatDate(payment.month, {
        month: "long",
      })}`;
    case "refund":
      return `${
        payment.description
          ? `Remboursement ${payment.description} `
          : "Autre Remboursement "
      }`.concat(
        formatDate(payment.month, {
          month: "long",
        })
      );
    default:
      return `${
        payment.description
          ? `Versement ${payment.description} `
          : "Autre Versement "
      }`.concat(
        formatDate(payment.month, {
          month: "long",
        })
      );
  }
};

export const amountForTransaction = (transaction: Transaction) => {
  if (transaction.__typename === "Invoice")
    return transaction.forecastSettledAmount;

  return transaction.__typename === "Settlement" ||
    transaction.paymentType === "refund"
    ? transaction.amount
    : -transaction.amount;
};

const getPaymentType = (payment: Payment): CategoryIconTypes =>
  `tx_${
    payment.paymentType as
      | "activity_report"
      | "lunch_voucher"
      | "other"
      | "refund"
      | "employee_savings"
      | "christmas_voucher"
      | "medical"
      | "employee_committee"
      | "culture_voucher"
  }`;

export const labelForTransaction = (transaction: Transaction): string =>
  transaction.__typename === "Payment"
    ? labelForPayment(transaction)
    : labelForInvoiceOrSettlement(transaction);

export const categoryForTransaction = (
  transaction: Transaction
): CategoryIconTypes =>
  transaction.__typename === "Payment"
    ? getPaymentType(transaction)
    : "tx_invoice";

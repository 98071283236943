import gql from "graphql-tag";

import { User } from "types";

export type MeData = {
  me: Pick<
    User,
    "id" | "firstName" | "lastName" | "email" | "profilePic" | "role" | "admin"
  >;
};

export const ME_QUERY = gql`
  {
    me {
      id
      firstName
      lastName
      email
      role
      profilePic
      admin
    }
  }
`;
